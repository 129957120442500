import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import IconComponent from "../../components/Icons";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import Icons from "../../components/Icons";
import withErrorBoundary from "../../utils/withErrorBoundary";
import { getCourseRecording } from "../../services/api/subject";
import { getToken } from "../../services/api/login";

// Interfaces for response data
interface Recording {
  class_rec_id: string;
  class_rec_name: string;
  class_rec_icon: string;
  class_rec: string | null;
  class_count: number;
  recordings_count: number;
}

interface CourseData {
  id: number;
  fk_student_id: number;
  fk_subject_id: number;
  fk_class_id: number;
  from_date: string;
  to_date: string | null;
  subject: string;
  subject_icon: string | null;
  subject_colour: string | null;
  subject_description: string | null;
  mst_subject_group_id: number;
  sub_code: string;
  recordings: Recording[];
}

interface SubjectDetails {
  subjectDetails: any[];
  loadingSubject: boolean;
  subject_id: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  marginTop: "8px",
  width: "131px",

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    border: "1px solid #D2D2D2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    border: "1px solid #308fe8",
  },
}));

const CourseContentGrid: React.FC<SubjectDetails> = ({
  subjectDetails,
  loadingSubject,
  subject_id
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleAOSSceen = (aos_id: any) => {
    const newSearch = new URLSearchParams(location.search);
    newSearch.set("aosID", aos_id);
    navigate(`/aos?${newSearch.toString()}`);
  };

  const handleAOSSceenClassRecId = (class_id: any) => {
    const newSearch = new URLSearchParams(location.search);
    newSearch.set("crecId", class_id);
    navigate(`/class-recording?${newSearch.toString()}`);
  };

  const SubjectProgress = ({ subject, percentage }: any) => (
    <>
      {subject.task_completion === subject.total_tasks &&
        subject.total_tasks !== 0 && (
          <>
            <Typography
              component={"div"}
              variant="detail"
              color={"#333333"}
              fontWeight={500}
              lineHeight={"15px"}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <IconComponent name="greenCheckCircle" width={16} height={16} />
              {subject.task_completion + " Resources Complete"}
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </>
        )}
      {subject.task_completion !== subject.total_tasks &&
        subject.total_tasks !== 0 &&
        subject.task_completion === 0 && (
          <>
            <Typography
              component={"div"}
              variant="detail"
              color={"#333333"}
              fontWeight={500}
              lineHeight={"15px"}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {subject.total_tasks > 1
                ? subject.total_tasks + " Resources"
                : subject.total_tasks + " Resource"}
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </>
        )}
      {subject.task_completion !== subject.total_tasks &&
        subject.task_completion > 0 && (
          <>
            <Typography
              component={"div"}
              variant="detail"
              color={"#333333"}
              fontWeight={500}
              lineHeight={"15px"}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {`${subject.task_completion}/${subject.total_tasks} Resources Complete`}
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </>
        )}
    </>
  );


  const [data, setData] = useState<CourseData[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchRecordings = async () => {
      if (!subject_id) return;

      setLoading(true);
      try {
        const accessstring = await getToken();
        const payload = { subject_id };
        const response = await getCourseRecording(payload, accessstring.token);

        const responseData = response?.data || {};

        if (responseData && Array.isArray(responseData.data)) {
          setData(responseData.data);
        } else {
          setError("No recordings found.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
        console.error("Error fetching course recordings:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecordings();
  }, [subject_id]);
  console.log('dataa-->', data?.[0]?.recordings[0]?.recordings_count)
  if (error) {
    return <>Something went wrong with CourseContentGrid Component</>
  }

  return (
    <Box
      sx={{
        paddingY: { md: "100px", xs: "24px", sm: "24px" },
        paddingX: { md: "30px", xs: "16px", sm: "16px" },
      }}
    >
      <Typography
        component={"div"}
        variant="title1"
        color={"contour.title2"}
        sx={{
          fontSize: { md: "28px", sm: "20px", xs: "20px" },
          marginX: { md: "12px", xs: "0", sm: "0" },
        }}
      >
        Course Content
      </Typography>
      {loadingSubject && loading ? (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"400px"}
          sx={{ marginTop: "30px" }}
        />
      ) : (
        <Box>
          <Box>
            {subjectDetails &&
              subjectDetails[0]?.area_of_study?.map(
                (subject: any, index: number) => {
                  const percentage =
                    (subject.task_completion / subject.total_tasks) * 100 || 0;

                  return (
                    <Box
                      key={index}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        borderBottom: "1px solid #EFEFEF",
                        paddingX: { md: "12px" },
                        paddingY: { md: "38px", xs: "24px", sm: "24px" },
                        borderRadius: "16px",
                        alignItems: "center",
                        gap: { md: "16px" },
                      }}
                      component={"div"}
                      onClick={() => handleAOSSceen(subject.id)}
                    >
                      <Box
                        sx={{ display: { md: "flex", sm: "flex", xs: "block" } }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {subject.area_of_study_icon !== null && (
                            <Box
                              sx={{
                                display: { md: "flex", sm: "flex", xs: "none" },
                              }}
                            >
                              <img
                                src={subject.area_of_study_icon}
                                alt="AOS Icon"
                                width={23}
                                height={23}
                                crossOrigin="anonymous"
                              />
                            </Box>
                          )}

                          {subject.area_of_study_icon === null &&
                            subjectDetails[0]?.subject_icon !== null && (
                              <Box
                                sx={{
                                  display: { md: "flex", sm: "flex", xs: "none" },
                                }}
                              >
                                <img
                                  src={subjectDetails[0]?.subject_icon}
                                  alt="AOS Icon"
                                  width={23}
                                  height={23}
                                  crossOrigin="anonymous"
                                />
                              </Box>
                            )}

                          {subject.area_of_study_icon === null &&
                            subjectDetails[0]?.subject_icon === null && (
                              <Box
                                sx={{
                                  display: { md: "flex", sm: "flex", xs: "none" },
                                }}
                              >
                                <Icons name="bookOpen" width={43} height={43} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
                              </Box>
                            )}

                          {subject.area_of_study_icon === null &&
                            subjectDetails[0]?.subject_icon !== null && (
                              <Box
                                sx={{
                                  display: { md: "none", sm: "none", xs: "flex" },
                                }}
                              >
                                <img
                                  src={subjectDetails[0]?.subject_icon}
                                  alt="AOS Icon"
                                  width={15}
                                  height={15}
                                  crossOrigin="anonymous"
                                />
                              </Box>
                            )}

                          {subject.area_of_study_icon === null &&
                            subjectDetails[0]?.subject_icon === null && (
                              <Box
                                sx={{
                                  display: { md: "none", sm: "none", xs: "flex" },
                                }}
                              >
                                <Icons name="bookOpen" width={30} height={30} />
                              </Box>
                            )}

                          {subject.area_of_study_icon !== null && (
                            <Box
                              sx={{
                                display: { md: "none", sm: "none", xs: "flex" },
                              }}
                            >
                              <img
                                src={subject.area_of_study_icon}
                                alt="AOS Icon"
                                width={15}
                                height={15}
                                crossOrigin="anonymous"
                              />
                            </Box>
                          )}
                          <Box marginLeft={{ md: "12px", sm: "12px", xs: "8px" }}>
                            <Typography
                              sx={{
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18.2px",
                                color: "#333",
                                textTransform: "capitalize",
                              }}
                            >
                              <span style={{ textTransform: "uppercase" }}>
                                {subject.aos_code || "AOS"}
                              </span>{" "}
                              {
                                subject.area_of_study == null ? '' : <span>- {subject.area_of_study}</span>
                              }
                            </Typography>
                            <Typography
                              sx={{
                                display: "block",
                                fontSize: {
                                  md: "14px",
                                  sm: "14px",
                                  xs: "12px",
                                },
                                fontWeight: "400",
                                lineHeight: {
                                  md: "18.2px",
                                  sm: "18.2px",
                                  xs: "15px",
                                },
                                color: "#696969",
                              }}
                            >
                              {subject.topic_count <= 1 ? `${subject.topic_count} topic` : `${subject.topic_count} topics`}
                            </Typography>
                          </Box>
                        </Box>

                        {/* Mobile Show Div */}
                        {isMobile && (
                          <Box sx={{ display: { sm: "none" }, ml: 1, mt: 1 }}>
                            <SubjectProgress
                              subject={subject}
                              percentage={percentage}
                            />
                          </Box>
                        )}
                      </Box>
                      {/* desktop Show Div */}
                      {!isMobile && (
                        <Box
                          ml="auto"
                          sx={{ display: { sm: "block", xs: "none" } }}
                        >
                          <SubjectProgress
                            subject={subject}
                            percentage={percentage}
                          />
                        </Box>
                      )}
                      {/* desktop Show Div */}
                      <Box sx={{ marginLeft: { md: "0", sm: "auto", xs: "auto" } }}>
                        <IconComponent
                          name="chevronRight"
                          width={35}
                          height={35}
                        />
                      </Box>
                    </Box>
                  );
                }
              )}
          </Box>
          {data?.[0]?.recordings?.[0]?.recordings_count !== undefined &&
             data[0].recordings[0].recordings_count >= 1 &&  (
          <Box marginTop={{ md: "12px" }}>
            {data && data.length > 0 && (
              <Typography
                component={"div"}
                variant="title1"
                color={"contour.title2"}
                sx={{
                  fontSize: { md: "28px", sm: "20px", xs: "20px" },
                  marginX: { md: "12px", xs: "12px", sm: "12px" },
                }}
              >
                Class Recording
              </Typography>
            )}
            <Box>
              {data?.map((course, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    borderBottom: "1px solid #EFEFEF",
                    paddingX: { md: "12px" },
                    paddingY: { md: "38px", xs: "24px", sm: "24px" },
                    borderRadius: "16px",
                    alignItems: "center",
                    gap: { md: "16px" },
                  }}
                  component={"div"}
                  onClick={() => handleAOSSceenClassRecId(course.recordings[index]?.class_rec_id)}

                >
                  {course.recordings.map((recording, idx) => (
                    <Box key={idx}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {recording.class_rec_icon !== null && (
                          <Box
                            sx={{
                              display: { md: "flex", sm: "flex", xs: "none" },
                            }}
                          >
                            {/* <img
                              src={recording.class_rec_icon || ""}
                              alt="AOS Icon"
                              width={23}
                              height={23}
                              crossOrigin="anonymous"
                            /> */}
                            <Icons name="bookOpen" width={43} height={43} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
                          </Box>
                        )}
                        {recording.class_rec_icon === null &&
                          (
                            <Box
                              sx={{
                                display: { md: "flex", sm: "flex", xs: "none" },
                              }}
                            >
                              <Icons name="bookOpen" width={43} height={43} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
                            </Box>
                          )}
                        {recording.class_rec_icon === null && (
                          <Box
                            sx={{
                              display: { md: "none", sm: "none", xs: "flex", marginLeft: "12px" },
                            }}
                          >
                            <Icons name="bookOpen" width={30} height={30} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
                          </Box>
                        )}
                        {recording.class_rec_icon !== null && (
                          <Box
                            sx={{
                              display: { md: "none", sm: "none", xs: "flex", marginLeft: "12px" },
                            }}
                          >
                            <Icons name="bookOpen" width={30} height={30} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
                          </Box>
                        )}
                        <Box sx={{ marginLeft: "12px" }}>
                          <Typography sx={{
                            display: "block",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "18.2px",
                            color: "#333",
                            textTransform: "capitalize",
                          }}>{recording.class_rec_name}</Typography>
                          <Typography
                            sx={{
                              display: "block",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "12px",
                              },
                              fontWeight: "400",
                              lineHeight: {
                                md: "18.2px",
                                sm: "18.2px",
                                xs: "15px",
                              },
                              color: "#696969",
                            }}
                          >
                            {recording.recordings_count <= 1
                              ? `${recording.recordings_count} Recording`
                              : `${recording.recordings_count} Recordings`}
                          </Typography>
                        </Box>


                      </Box>
                    </Box>
                  ))}

                  {!isMobile && (
                    <Box
                      ml="auto"
                      sx={{ display: { sm: "block", xs: "none" } }}
                    >
                      {/* <SubjectProgress
                    subject={subject}
                    percentage={percentage}
                  /> */}
                    </Box>
                  )}
                  {/* desktop Show Div */}
                  <Box sx={{ marginLeft: { md: "0", sm: "auto", xs: "auto" } }}>
                    <IconComponent
                      name="chevronRight"
                      width={35}
                      height={35}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

export default withErrorBoundary(CourseContentGrid, <>Something went wrong with CourseContentGrid Component</>);

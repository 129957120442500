import React, { memo, useRef } from "react";
import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer"
import "@cyntler/react-doc-viewer/dist/index.css"
import "../../components/PdfViewer/pdfFile.css";
import IconComponent from "../Icons";
import { aosLogFileAccessTime, decodePDF } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { formatDate } from "../../utils/utilfns";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface FilesPreviewProps {
  fileUrl: string;
  fileId: number;
  password: string;
  isFullContainer: boolean;
}

const FilesPreview: React.FC<FilesPreviewProps> = memo(
  ({ fileUrl, fileId, password, isFullContainer }) => {
    const [docs, setDocs] = React.useState<any>([{ uri: "" }]);
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );
    const docViewerRef = useRef<any>(null);
    const pdfRef = useRef<any>(null);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [pdfLoading, setpdfLoading] = React.useState(true);
    const [pdfLoadingError, setpdfLoadingError] = React.useState(false);

    const handleFileLogTime = async (
      file_id: any,
      startTime: any,
      endTime: any
    ) => {
      const verifyAccess = getToken();
      try {
        const payload = {
          file_id: file_id,
          starttime: startTime,
          endtime: endTime,
        };
        const response = await aosLogFileAccessTime(
          payload,
          verifyAccess.token
        );
        console.log("file log time", response?.data.data);
      } catch (error) {
        console.error("Error fetching file type details:", error);
      }
    };

    React.useEffect(() => {
      setDocs([{ uri: "" }]);
      setpdfLoading(true);
      const verifyAccess = getToken();
      decodePDF(verifyAccess.token, fileId, password)
        .then((res) => {
          if (res.status) {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            const url = URL.createObjectURL(pdfBlob);
            setDocs([{ uri: url }]);
            setpdfLoading(false);
          } else {
            setDocs([{ uri: "" }]);
            setpdfLoadingError(true);
            setpdfLoading(false);
          }
        })
        .catch(() => {
          setDocs([{ uri: "" }]);
          setpdfLoadingError(true);
          setpdfLoading(false);
        });
    }, [fileUrl]);

    React.useEffect(() => {
      const currentTime = formatDate();
      return () => {
        const endtime = formatDate();
        handleFileLogTime(fileId, currentTime, endtime);
      };
    }, [fileUrl]);

    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    React.useEffect(() => {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }, []);

    // const handleFullScreenMode = () => {
    //   if (docViewerRef.current) {
    //     if (!isFullscreen) {
    //       docViewerRef.current.requestFullscreen().catch((err) => {
    //         console.error("Error attempting to enable fullscreen mode:", err);
    //       });
    //     } else {
    //       document.exitFullscreen().catch((err) => {
    //         console.error("Error attempting to exit fullscreen mode:", err);
    //       });
    //     }
    //   }
    // };
    const handleFullScreenMode = () => {
      if (docViewerRef.current) {
        const element = docViewerRef.current;

        if (element.requestFullscreen) {
          // Standard Fullscreen API
          element.requestFullscreen().catch((err: any) => {
            console.error("Error attempting to enable fullscreen mode:", err);
          });
        } else if (element.webkitRequestFullscreen) {
          // Safari-specific API
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          // Older IE/Edge API
          element.msRequestFullscreen();
        } else {
          // Fallback: Simulate fullscreen with CSS
          element.style.position = "fixed";
          element.style.top = "0";
          element.style.left = "0";
          element.style.width = "100vw";
          element.style.height = "100vh";
          element.style.zIndex = "9999";
          setIsFullscreen(true);
        }
      }
    };

    const exitFullScreenMode = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        // Safari-specific API
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        // Older IE/Edge API
        (document as any).msExitFullscreen();
      } else {
        // Fallback: Reset styles
        if (docViewerRef.current) {
          const element = docViewerRef.current;
          element.style.position = "";
          element.style.top = "";
          element.style.left = "";
          element.style.width = "";
          element.style.height = "";
          element.style.zIndex = "";
        }
        setIsFullscreen(false);
      }
    };

    const MyLoadingRenderer = () =>
      pdfLoading ? <div>Loading ...</div> : <div>Loading Renderer...</div>;

    const MyNoRenderer = ({ document, fileName }: any) => {
      const fileText = fileName || document?.fileType || "";
      return document.uri !== "" ? (
        <div>No Renderer Error! ({fileText})</div>
      ) : (
        <></>
      );
    };

    return (
      <Box
        className={"pdfMain"}
        sx={{ position: "relative" }}
        ref={docViewerRef}
      >
        {pdfLoading ? (
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={"calc(100vh - 176px)"}
            animation="wave"
          />
        ) : pdfLoadingError ? (
          <Box
            width={"100%"}
            height={isMobile ? "calc(100vh - 100px)" : "calc(100vh - 176px)"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">No PDF to render</Typography>
          </Box>
        ) : (
          <DocViewer
            pluginRenderers={[PDFRenderer]}
            ref={pdfRef}
            documents={docs}
            config={{
              pdfVerticalScrollByDefault: true,
              loadingRenderer: { overrideComponent: MyLoadingRenderer },
              noRenderer: { overrideComponent: MyNoRenderer },
              header: {
                disableHeader: true,
                disableFileName: false,
                retainURLParams: false,
              },
              pdfZoom: {
                defaultZoom: isMobile ? 1.3 : isFullContainer ? 1 : 0.7,
                zoomJump: 0.1,
              },
            }}
            style={{
              height: isFullscreen
                ? "100vh"
                : isMobile
                  ? "calc(100vh - 300px)"
                  : "calc(100vh - 200px)",
              backgroundColor: "#2B3258",
              overflowY: "scroll",
              borderRadius: isFullscreen ? "0px" : "16px",
            }}
          />
        )}
        {!pdfLoading && (
          <Box
            sx={{
              position: "absolute",
              top: { md: "8px", sm: "6px", xs: "6px" },
              right: { md: "30px", sm: "20px", xs: "20px" },
              padding: "4px",
              display: "flex",
              border: "1px solid transparent",
              zIndex: "10",
              ":hover": {
                border: "1px solid #D2D2D0",
                background: "#FFF8EC1A",
                borderRadius: "8px",
              },
            }}
            onClick={() => {
              isFullscreen ? exitFullScreenMode() : handleFullScreenMode();
            }}
          >
            <IconComponent
              name="expandVideoFull"
              fill={"#FFFFFF"}
              width={20}
              height={20}
            />
          </Box>
        )}
      </Box>
    );
  }
);

export default withErrorBoundary(
  FilesPreview,
  <>Something went wrong with FilesPreview Component</>
);
